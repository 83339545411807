import React, { useEffect, useState, useRef } from "react"
import { Link, navigate } from "gatsby"

import Image from "~/components/Images"

import { Navbar, Nav, NavDropdown } from "react-bootstrap"
import { FaFacebook, FaInstagram } from "react-icons/fa"

const useScrollHandler = handler => {
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 550) {
        handler(true)
      } else {
        handler(false)
      }
    })
    return () => {
      window.removeEventListener("scroll", () => {
        handler()
      })
    }
  }, [handler])
}

const CustomNavbar = ({ pageInfo }) => {
  const navRef = useRef()

  const handler = value => {
    value
      ? navRef.current.classList.add("small-nav")
      : navRef.current.classList.remove("small-nav")
  }

  useScrollHandler(handler)

  return (
    <div className={` site-navbar--wrapper`} ref={navRef}>
      <Navbar expand="lg" id="site-navbar" sticky="top" collapseOnSelect>
        {/* <Container> */}

        <Link to="/" className="link-no-style">
          <Navbar.Brand as="span">
            <div className="navbar-logo--image">
              <Image
                fileName="nav-logo.png"
                loading="eager"
                style={{ height: "100%", width: "inherit" }}
              />
            </div>
          </Navbar.Brand>
        </Link>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end">
          <Nav activeKey={pageInfo && pageInfo.pageName}>
            <div className="nav-item__div">
              <NavDropdown
                title="Custom Projects"
                id="basic-nav-dropdown"
                rootCloseEvent="click"
              >
                <NavDropdown.Item>
                  <Link
                    className=" brazee__nav-link"
                    activeClassName="nav-active"
                    to="/corporate-awards-and-production"
                  >
                    Corporate Awards & Production
                  </Link>
                </NavDropdown.Item>

                <NavDropdown.Divider />
                <NavDropdown.Item>
                  <Link
                    className=" brazee__nav-link"
                    activeClassName="nav-active"
                    to="/large-scale-installations"
                  >
                    Large Scale Installations
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item>
                  {" "}
                  <Link
                    className=" brazee__nav-link"
                    activeClassName="nav-active"
                    to="/community-projects"
                  >
                    Community Projects{" "}
                  </Link>
                </NavDropdown.Item>

                <NavDropdown.Divider />
                <NavDropdown.Item>
                  {" "}
                  <Link
                    className=" brazee__nav-link"
                    activeClassName="nav-active"
                    to="/waterjet-services"
                  >
                    Water Jet Services{" "}
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
            </div>

            <div className="nav-item__div">
              <Nav.Item>
                <Link
                  className="retail__link brazee__nav-link"
                  activeClassName="nav-active"
                  to="/glass-retail-and-gift-shop"
                >
                  Glass Retail
                </Link>
              </Nav.Item>
            </div>

            <div className="nav-item__div">
              <NavDropdown title="Glassroom" id="basic-nav-dropdown">
                <NavDropdown.Item
                  href="https://www.glassroomart.com/about"
                  taget="_blank"
                >
                  About Glassroom
                </NavDropdown.Item>

                <NavDropdown.Divider />
                <NavDropdown.Item
                  href="https://www.glassroomart.com/kits"
                  taget="_blank"
                >
                  Glass Kits
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  href="https://www.glassroomart.com/glass-classes"
                  taget="_blank"
                >
                  Glass Classes
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </Nav>
          <div className="icon-wrap">
            <FaFacebook
              onClick={() => navigate("https://www.facebook.com/brazeestudios")}
              className="navbar-icon"
            />
            <FaInstagram
              onClick={() =>
                navigate("https://www.instagram.com/brazeestudios/")
              }
              className="navbar-icon"
            />
          </div>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}

export default CustomNavbar
