import React from "react"
import { StaticQuery, graphql } from "gatsby"
import AppContextProvider from "~/context/AppContext"
import { GlobalStyle } from "../utils/styles"
import Progress from "~/components/Progress"

import Footer from "~/components/Footer"
import Navbar from "~/components/NavBar"
import styled from "@emotion/styled"
import LoadingScreen from "../components/Loading"
import SEO from "../components/seo"

const SiteWrap = styled.div`
  position: relative;
  /* width: 100vw; */
  /* max-width: 1920px; */
  margin: auto;
`

export default ({ children, pageInfo }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              siteUrl
              description
            }
          }
        }
      `}
      render={data => (
        <>
          <AppContextProvider>
            <GlobalStyle />
            <Progress />
            <LoadingScreen />
            <SEO
              title={`${data.title}`}
              description={`${data.description}`}
              pagePath="/"
              schema={`{
              "@context": "http://schema.org",
              "@type": "WebPage",
              "mainEntity": {
                "@type": "Website",
                "name": "Brazee Street Studios",
                "image": "${data.siteUrl}/img/logo.png"
              }
            }`}
            />
            <SiteWrap>
              <Navbar pageInfo={pageInfo} />
              {children}
              <Footer />
            </SiteWrap>
          </AppContextProvider>
        </>
      )}
    />
  )
}
