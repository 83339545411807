/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/style.scss"
// import "./static/jquery.min.js"
import SimpleReactLightBox from "simple-react-lightbox"

import React from "react"

export const wrapRootElement = ({ element }) => {
  return <SimpleReactLightBox>{element}</SimpleReactLightBox>
}

export const onRouteUpdate = ({ location }) => {
  console.log("new pathname", location.pathname)
}
