import React, { useState } from "react"

const initialState = {
  loading: false,
  message: "Loading...",
  lightbox: {
    showing: false,
    src: "",
    title: "",
    description: "",
  },
  lightboxChild: null,
}
export const AppContext = React.createContext()

const AppContextProvider = ({ children }) => {
  const [appState, setAppState] = useState(initialState)
  // useEffect(() => {
  //   return globalHistory.listen(({ action }) => {
  //     if (action === "PUSH") setLoading({ loading: true })
  //   })
  // }, [setAppState])

  const setLoading = payload => {
    if (!payload.message) {
      payload.message = ""
    }

    const state = { ...appState }

    state.loading = payload.loading
    state.message = payload.message

    setAppState(state)
  }

  const setLoadingMessage = message => {
    setAppState({ ...appState, message: message })
  }

  const setLightBox = value => {
    setAppState(prev => {
      return { ...prev, lightbox: value }
    })
  }

  const killLightbox = value => {
    setAppState(prev => {
      return {
        ...prev,
        lightbox: { ...prev.lightbox, showing: false },
      }
    })
  }

  return (
    <AppContext.Provider
      value={{
        killLightbox,
        setLightBox,
        appState,
        setLoadingMessage,
        setLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppContextProvider
