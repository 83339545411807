import React from "react"
import { css, Global } from "@emotion/core"

export const GlobalStyle = props => (
  <Global
    {...props}
    styles={css`
      @import url("https://use.typekit.net/gjt1tan.css");

      :root {
        --brz-blue: #4fc0dd;
        --gr-orange: #ea3626;
        --gr-loading: #64c9e3;
        --gr-grey: #5f5f5f;
        --brz-grey: #5f5f5f;
        --brz-black: #181818;
        --brz-primary-font: questa-slab, serif;
        --brz-bold: 500;
        --p-font-size: 1.25em;
        --p-fam: questa-slab, serif;
      }
      @keyframes buttonAnimate {
        0% {
          width: 0%;
          height: 0%;
        }
        50% {
          width: 50%;
          height: 100%;
        }
        100% {
          width: 100%;
          height: 100%;
        }
      }

      body,
      html {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        font-size: 16px;
        font-family: sans-serif;
        scroll-behavior: smooth;
        font-family: questa-slab, serif;
        min-width: 100%;
        min-width: 100vw;
        background-color: #202020;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%239a9a9a' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
        hyphens: none;
        min-height: 100vh;
        transform: translate3d(0);
      }

      .row {
        background-color: white;
      }

      .blue {
        color: var(--brz-blue) !important;
      }
      .big-p {
        color: var(---gr-grey);
        font-size: 36px;
      }
      .white {
        color: #ffffff;
      }
      .white-bg {
        background-color: white;
      }
      a {
        color: var(--brz-blue);
        text-decoration: none;
      }
      a:hover {
        color: var(--brz-orange);
        text-decoration: none;
      }

      a::visited {
        color: var(--gr-blue);
      }

      strong {
        font-weight: 600;
      }
      button.btn {
        margin-top: 1rem;
        background-color: var(--gr-orange);
        cursor: pointer;
        position: relative;
        transition: all 0.2s;
        font-family: questa-slab, serif;
      }
      button.btn::after {
        width: 0%;
        background-color: #ff0e00;
        opacity: 0.9;
        height: 100%;
        left: 0;
        bottom: 0;
        content: " ";
        position: absolute;
        transition: all 0.1s;

        z-index: -1;
      }
      button.btn:hover {
        opacity: 0.9;
        color: white;
      }
      button.btn:hover::after {
        width: 100%;
        height: 100%;
      }

      button:disabled,
      button[disabled] {
        border: none;
        background-color: #cccccc;
        color: #666666;
      }

      button:disabled:hover::after,
      button[disabled]:hover::after {
        background-color: #f7f7f7;
        cursor: not-allowed;
      }
      select {
        min-width: 200px;
        max-width: 500px;
        padding: 5px;
        font-size: 24px;
        font-family: questa-slab, serif;
        color: var(--gr-grey);
      }
      select.gr-select::after {
        content: "<>";
        position: absolute;
        left: 0;
        height: 50px;
        width: 50px;
        background-color: red;
      }

      h1,
      h2 {
        font-size: 3.5rem;
        color: white;
        font-family: questa-slab, serif;
        font-style: bold;
        font-weight: 600;
        line-height: auto;
        margin: 0;
      }
      .blue {
        color: var(--gr-blue);
      }
      .orange {
        color: var(--gr-orange);
      }
      .grey {
        color: var(---brz-grey);
      }

      h3 {
        font-family: questa-slab, serif;
        font-size: 3rem;
        font-style: regular;
        font-weight: 200;
        color: var(--gr-grey);
      }
      blockquote {
        border-left: 2px solid var(--brz-grey);
        padding: 2rem;
        margin-left: 1rem;
      }
      .white-cta > blockquote {
        border-left: 2px solid white;
        margin-top: 2rem;
        padding: 1rem;
      }

      div {
        color: var(--gr-grey);
      }

      @media all and (max-width: 1000px) {
        h1,
        h2,
        h3,
        h4 {
          text-align: center;
          font-size: 3rem;
        }
      }
      h6 {
        font-family: questa-slab, serif;
        color: var(--brz-grey);
        font-size: 1.5rem;
        font-weight: 100;
      }
      p {
        font-family: questa-slab, serif;
        font-weight: 100;
        font-size: 1.35rem;
      }

      button.btn {
        font-size: 24px;
        border-radius: 0px;
        color: white;
        border: none;
        width: 200px;
        height: 60px;
        background-color: var(--gr-orange);
        cursor: pointer;
        display: block;
      }

      button:focus {
        outline: none !important;
      }

      *:focus {
        outline: none !important;
      }

      .centered {
        margin-left: auto;
        margin-right: auto;
      }

      h4 {
        font-family: questa-slab, serif;
        color: white;
        font-size: 2rem;
      }

      h5 {
        font-family: questa-slab, serif;
        color: var(--gr-grey);
        font-size: 1.5rem;
      }

      .navbar-toggler {
        width: 80px;
      }

      @media all and (max-width: 650px) {
        body {
          overflow-x: hidden;
        }
        h1,
        h2 {
          font-size: 2rem;
          color: var(--brz-grey);
        }
        h3 {
          font-size: 1rem;
          color: var(--brz-grey);
        }
        h6 {
          font-size: 1rem;
        }
        blockquote {
          font-size: 0.86rem;
        }
        p {
          font-size: 1rem;
        }
      }
      @media all and (max-width: 550px) {
        body {
          overflow-x: hidden;
        }
        h1,
        h2 {
          font-size: 1.75rem;
          color: var(--brz-grey);
        }
        h3 {
          font-size: 1rem;
          color: var(--brz-grey);
        }
        h6 {
          font-size: 1rem;
        }
        blockquote {
          font-size: 0.85rem;
        }
        p {
          font-size: 1rem;
        }
      }
    `}
  />
)
