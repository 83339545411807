import React, { useContext, useEffect, useRef } from "react"

import styled from "@emotion/styled"
import Image from "../Images"
import "./loading.css"
import { AppContext } from "../../context/AppContext"

const LoadingBlock = styled.div`
  position: fixed;
  background-color: white;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 999;
  width: 100vw;
  transition: all 0.5s ease;
  opacity: 1;
  &&.loaded {
    opacity: 0;
  }
  &&.hidden {
    display: none;
  }
`
const LoadingScreen = () => {
  const loadingRef = useRef()
  const {
    appState: { loading },
  } = useContext(AppContext)

  useEffect(() => {
    if (loading) {
      window.scrollTo(0, 0)
    }
    if (!loading) {
      setTimeout(() => loadingRef.current.classList.add("hidden"), 500)
    }
  }, [loading])

  return (
    <LoadingBlock ref={loadingRef} className={loading ? "" : "loaded"}>
      <Image
        className="loading-icon"
        fileName={"apple-touch-icon.png"}
        style={{ height: "80px", width: "80px" }}
      />
    </LoadingBlock>
  )
}

LoadingScreen.propTypes = {}

export default LoadingScreen
