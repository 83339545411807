import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Image = ({ fileName, alt, style, loading, className, objectFit }) => {
  const { allImageSharp } = useStaticQuery(graphql`
    query {
      allImageSharp {
        nodes {
          fluid(maxWidth: 1920, quality: 100) {
            originalName
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const fluid = allImageSharp.nodes.find(n => n.fluid.originalName === fileName)
    .fluid

  return (
    <Img
      objectFit={objectFit}
      fluid={fluid}
      alt={alt}
      loading={loading}
      style={style}
      className={className}
    />
  )
}

export default Image
