import React from "react"

import { Link, navigate } from "gatsby"
const index = props => {
  return (
    <div className="footer--wrap">
      <div className="footer-top">
        <div
          onClick={() => navigate("/")}
          className="brand"
          role="back to home"
        >
          Brazee Studios
        </div>
        <div className="info">
          4426 Brazee Street Cincinnati, OH 45209 | 513-321-0206 |
          <a href="mailto:info@brazeestudios.com"> info@brazeestudios.com</a>
        </div>
      </div>
      <hr />
      <footer>
        <div className="inner">
          <li>
            <Link to="/about-us">Our Team</Link>
          </li>
          <li>
            <Link to="/green-brazee">Our Studio</Link>
          </li>
          <li>
            <Link to="/contact-us">Contact Us</Link>
          </li>
          <li>
            <Link to="/studio-rental">Studio Rental + Artists</Link>
          </li>
          <li>
            <Link to="/glass-retail-and-gift-shop">Glass Retail</Link>
          </li>
          <li>
            <a className="white" href="https://www.glassroomart.com">
              Glassroom
            </a>
          </li>
          <li>
            <Link to="/corporate-awards-and-production">
              Corporate Awards & Production
            </Link>
          </li>
          <li>
            <Link to="/community-projects">Community Projects</Link>
          </li>
          <li>
            <Link to="/waterjet-services">Water Jet Services</Link>
          </li>
        </div>
      </footer>
      {/* <span className="aggregate">
          © {new Date().getFullYear()} Designed & Built By
          {` `}
          <a href="#">Aggregate Media</a>
        </span> */}
    </div>
  )
}

index.propTypes = {}

export default index
